<template>
    <div class="news-item">
        <div class="news-item-image-cont">
            <a :href="link" target="_blank">
                <img 
                    class="news-item-image" 
                    :src="image"
                >
            </a>
        </div>
        <div class="news-item-cont">
            <h4 class="news-item-title">{{ title }}</h4>
            <h5 class="news-item-date">{{ date }}</h5>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NewsItem',
  props: {
    newsID: {
        type: String,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
    ...mapGetters([
      'news'
    ]),
    newsItem() {
        return this.news[this.newsID];
    },
    image() {
        return this.newsItem.image;
    },
    link() {
        return this.newsItem.link;
    },
    title() {
        return this.newsItem.title;
    },
    date() {
        return this.newsItem.date;
    }
  },
  created() {
  }

}
</script>

<style scoped>
.news-item {
    width: 100%;
    float: left;
}
.news-item-image-cont {
    width: 40%;
    float: left;
}
.news-item-image {
    width: 100%;
    float: left;
}
.news-item-cont {
    width: 60%;
    padding-left: 4%;
    float: left;
}
.news-item-title {
    font-size: 1em;
    float: left;
}
.news-item-date {
    font-size: 0.8em;
    float: left;
}
</style>
