import sysOutDev from '../../data/sysOut';

let organization

if (process.env.NODE_ENV === 'development') {
    organization = sysOutDev.organization;
} else {
    organization = window.sysOut.organization;
}

export default {
    state: () => ({
        organization: organization
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
        organization: state => {
            return state.organization;
        },
    }
}
