import sysOutDev from '../../data/sysOut';

let members

if (process.env.NODE_ENV === 'development') {
    members = sysOutDev.members;
} else {
    members = window.sysOut.members;
}

export default {
    state: () => ({
        members: members
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
        members: state => {
            return state.members;
        },
    }
}