<template>
  <div class="page">
    <section class="news">
      <NewsList :newsList="newsList" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewsList from "../components/news/NewsList.vue";

export default {
  name: "News",
  components: {
    NewsList
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
        'newsList'
    ]),
  },
  methods: {
  },
  created() {
  },
};
</script>

<style scoped>
.news {
  padding-bottom: 2%;
}
</style>
