export default {
    state: () => ({
        months: {
            "1": "Jan",
            "2": "Feb",
            "3": "Mar",
            "4": "Apr",
            "5": "May",
            "6": "Jun",
            "7": "Jul",
            "8": "Aug",
            "9": "Sep",
            "10": "Oct",
            "11": "Nov",
            "12": "Dec",
        },
        fileTypes: {
            "Lite.mp4": {
                image: "https://sawbo-animations.org/images/download_buttons/whatsapp_mp4Lite.png",
                device: "WhatsApp",
                deviceTranslated: "WhatsApp",
                fileType: ".mp4 Lite",
                alt: "Download Mp4 Lite",
            },
            ".mp4": {
                image: "https://sawbo-animations.org/images/download_buttons/computer_mp4.png",
                device: "Computer",
                deviceTranslated: "Ordinateur",
                fileType: ".mp4",
                alt: "Download Mp4",
            },
            ".mov": {
                image: "https://sawbo-animations.org/images/download_buttons/broadcast_mov.png",
                device: "Broadcast",
                deviceTranslated: "Diffusion",
                fileType: ".mov",
                alt: "Download Mov",
            },
            "Light.3gp": {
                image: "https://sawbo-animations.org/images/download_buttons/cellphone_3gpLite.png",
                device: "Cellphone",
                deviceTranslated: "Téléphone portable",
                fileType: ".3gp Lite",
                alt: "Download 3gp Lite",
            },
            ".3gp": {
                image: "https://sawbo-animations.org/images/download_buttons/smartphone_3gp.png",
                device: "Smartphone",
                deviceTranslated: "Smartphone",
                fileType: ".3gp",
                alt: "Download 3gp",
            },
            ".f4v": {
                image: "https://sawbo-animations.org/images/download_buttons/computer_mp4.png",
                device: "Computer",
                deviceTranslated: "Ordinateur",
                fileType: ".f4v",
                alt: "Download F4v",
            },
            ".flv": {
                image: "https://sawbo-animations.org/images/download_buttons/computer_mp4.png",
                device: "Computer",
                deviceTranslated: "Ordinateur",
                fileType: ".flv",
                alt: "Download Flv",
            },
            ".mp3": {
                image: "https://sawbo-animations.org/images/download_buttons/audio_mp3.png",
                device: "Audio",
                deviceTranslated: "L'audio",
                fileType: ".mp3",
                alt: "Download Mp3",
            },
        },
        sdgArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
        months: state => {
            return state.months;
        },
        fileType: state => fileName => {
            let c = fileName.indexOf(".");
            let f = fileName.slice(c);
            if (f === ".3gp" && fileName.includes("_Light.")) {
                f = "Light.3gp";
            } else if (f === ".mp4" && fileName.includes("_Lite.")) {
                f = "Lite.mp4";
            }
            return state.fileTypes[f];
        },
        sdgArray: state => {
            return state.sdgArray;
        },
    }
}
