<template>
    <div class="video-item">
        <img 
            class="video-item-image" 
            :src="memberImage"
        >
        <h4 class="video-item-title">{{ memberTitle }}</h4>
    </div>
</template>

<script>

export default {
  name: 'MemberItem',
  props: {
    member: {
        type: Object,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
    memberImage() {
        return "https://sawbo-animations.org/ccardesa/img/members/" + this.member?.image;
    },
    memberTitle() {
        return this.member?.title;
    }
  },
  created() {
  }

}
</script>

<style scoped>
.video-item {
    width: 100%;
}
.video-item-image {
    width: 100%;
    padding-bottom: 2%;
    vertical-align: top;
}
.video-item-title {
    font-size: 1.2em;
}

@media only screen and (min-width: 745px) {
.video-item {
    width: 95%;
    padding-right: 5%;
}
.video-item-image {
    width: 100%
}
.video-item-title {
    font-size: .95em;
}
}
</style>
