<template>
    <div class="project-list">
        <ProjectItem 
            v-for="p in projects" :key="p"
            :project="p"
            class="project-item"
        />
    </div>
</template>

<script>
import ProjectItem from './ProjectItem'

export default {
  name: 'ProjectList',
  components: {
      ProjectItem,
  },
  props: {
    projects: {
        type: Array,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
  },
  created() {
  }

}
</script>

<style scoped>
.project-list {
    width: 100%;
}
.project-item {
    width: 80%;
    display: block;
    margin: auto;
    padding-bottom: 5%;
    border-bottom: 2px solid gray;
}
</style>
