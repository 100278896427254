<template>
    <div class="news-list">
        <NewsItem 
            v-for="n in newsList" :key="n.id"
            :newsID="n.id"
            class="news-item"
        />
    </div>
</template>

<script>
import NewsItem from './NewsItem'

export default {
  name: 'NewsList',
  components: {
      NewsItem,
  },
  props: {
    newsList: {
        type: Array,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
  },
  created() {
  }

}
</script>

<style scoped>
.news-list {
    width: 100%;
}
.news-item {
    width: 100%;
    display: block;
    padding-bottom: 10%;
}
</style>
