<template>
    <div class="project-item">
        <div class="project-item-details">
            <h4 class="project-item-title">{{ projectTitle }}</h4>
            <div class="project-item-sub-details">
                <div class="project-item-location">{{ projectLocation }}</div>
                <div class="project-item-date">Date(s): {{ projectDisplayDate }}</div>
            </div>
        </div>
        <img 
            class="project-item-image" 
            :src="projectImage"
        >
        <div>Supporting Documents:</div>
        <ul v-for="d in documents" :key="d">
            <li v-if="d.link"><a :href="d.link" target="_blank">{{ d.name }}</a></li>
            <li v-else>{{ d.name }}</li>
        </ul>
    </div>
</template>

<script>

export default {
  name: 'ProjectItem',
  props: {
    project: {
        type: Object,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
    projectImage() {
        return "https://sawbo-animations.org/ccardesa/projects/images/" + this.project?.image;
    },
    projectTitle() {
        return this.project?.title;
    },
    projectLocation() {
        return this.project?.location;
    },
    documents() {
        return this.project?.documents;
    },
    projectDisplayDate() {
        return this.project?.displayDate;
    },
    dateDisplay() {
        if (this.project?.multipleDate && this.project.multipleDate.length > 0) {
            // multiple separate dates
            let dateString = "";
            this.project.multipleDate.forEach((d) => {
                dateString += this.showMonthDay(d) + ", ";
            });
            return dateString.slice(0, -2);
        } else if (this.project?.endDate && this.project?.startDate) {
            // date range
            return this.showMonthDay(this.project.startDate) + " - " + this.showMonthDay(this.project.endDate);
        } else if (this.project?.startDate) {
            // single date
            return this.showFullDate(this.project.startDate);
        } else {
            return "none";
        }
    },
  },
  methods: {
    showFullDate(d) {
        let date = new Date(d);
        return date.toDateString();
    },
    showMonthDay(d) {
        let date = new Date(d);
        return date.getDate() + " " + date.getMonth();
    },
    showDay(d) {
        let date = new Date(d);
        return date.getDate();
    },
  },
  created() {
  }

}
</script>

<style scoped>
.project-item {
    width: 100%;
}
.project-item-image {
    width: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    /*padding: 0 2% 2% 2%;
    filter: drop-shadow( rgb(0,0,0,0.2) 3px 3px 3px);*/
}
.project-item-details {
    width: 100%;
}
.project-item-title {
    font-size: 1.2em;
    padding-bottom: 2%;
}
.project-item-sub-details {
    width: 100%;
}
.project-item-location {
    width: 50%;
    float: left;
}
.project-item-date {
    width: 50%;
    float: left;
    text-align: right;
}

@media only screen and (min-width: 745px) {
.project-item-title {
    font-size: .95em;
}
}
</style>
