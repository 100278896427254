import { createRouter, createWebHistory} from 'vue-router'

// Pages
import About from '../pages/About';
import Members from '../pages/Members';
import News from '../pages/News';
import Videos from '../pages/Videos';
import Video from '../pages/Video';
import Projects from '../pages/Projects';

const routes = [
    {
        path: '/about',
        name: 'about',
        component: About
    },
    {
        path: '/members',
        name: 'members',
        component: Members
    },
    {
        path: '/projects',
        name: 'projects',
        component: Projects
    },
    {
        path: '/news',
        name: 'news',
        component: News
    },
    {
        path: '/video/:videoID',
        props: true,
        name: 'video',
        component: Video
    },
    {
        path: '/',
        name: 'videos',
        component: Videos
    }
]

const router = createRouter({
    history: createWebHistory(),
    //history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
  })

export default router
