import sysOutDev from '../../data/sysOut';

let siteInfo

if (process.env.NODE_ENV === 'development') {
    siteInfo = sysOutDev.siteInfo;
} else {
    siteInfo = window.sysOut.siteInfo;
}

export default {
    state: () => ({
        siteInfo: siteInfo
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
        siteInfo: state => {
            return state.siteInfo;
        },
    }
}