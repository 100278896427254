import { createStore } from 'vuex'
import video from './modules/video';
import organization from './modules/organization';
import siteInfo from './modules/siteInfo';
import members from './modules/members';
import news from './modules/news';
import projects from './modules/projects';
import constants from './modules/constants';
import utils from './modules/utils';

export default createStore({
    modules: {
      video,
      organization,
      siteInfo,
      members,
      news,
      projects,
      constants,
      utils,
    }
})
