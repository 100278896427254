<template>
  <div class="page">
    <section class="about">
      <div v-if="showMultiple" class="language-buttons">
        <button 
          v-for="file in aboutFiles" 
          :key="file.filename"
          @click="setFilename(file.filename)"
          class="language-button"
        >
          {{ file.button }}
        </button>
      </div>
      <html-loader :filename="currentFile" :key="currentFile" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HtmlLoader from "../components/utils/HtmlLoader";

export default {
  name: "About",
  /*metaInfo() {
    return {
      title: "About SAWBO and "+this.abbr,
      meta: [
        {
          name: "description",
          content: "Funded through a grant from Feed the Future, the U.S. Government's global hunger and food security initiative, SAWBO RAPID serves as an educational intervention program to disseminate crucial information related to COVID-19's secondary economic impacts, including disruption to trade, supply chains and markets."
        },
        { property: "og:title", content: "About SAWBO and "+this.abbr },
        { property: "og:image", content: "https://sawbo-animations.org/images/social_media/SAWBO_FB_Share.png" },
        { property: "og:url", content: window.location.origin + window.location.pathname },
        { property: "og:type", content: "website" }
      ],
    };
  },*/
  components: {
    HtmlLoader
  },
  data() {
    return {
      filename: ""
    };
  },
  computed: {
    ...mapGetters([
        'organization',
        'siteInfo'
    ]),
    name() {
      return this.organization.name;
    },
    abbr() {
      return this.organization.abbreviation;
    },
    displayName() {
      if (this.abbr) {
        return this.name + " (" + this.abbr + ")";
      } else {
        return this.name;
      }
    },
    aboutFiles() {
      return this.siteInfo.aboutFiles;
    },
    showMultiple() {
      if (this.siteInfo.aboutFiles.length > 1) {
        return true;
      } else {
        return false;
      }
    },
    currentFile() {
      return this.filename;
    }
  },
  methods: {
    setFilename(name) {
      this.filename = name;
      console.log(this.filename);
    }
  },
  created() {
    this.setFilename(this.aboutFiles[0].filename);
  },
};
</script>

<style scoped>
.about {
  padding-bottom: 2%;
}
.blue-hr {
  color: #3b5783;
  margin: 4% 0;
}
.language-buttons {
  width: 100%;
  padding: 2% 0;
}
.language-button {
  padding: 1.5%;
  margin-right: 2.5%;
  background-color: #3b5783;
  color: #FFF;
  border: none;
  cursor: pointer;
}
</style>
