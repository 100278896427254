import sysOutDev from '../../data/sysOut';

let videos;
let topics;
let titleTranslations;

if (process.env.NODE_ENV === 'development') {
    videos = sysOutDev.videos;
    topics = sysOutDev.topics;
    titleTranslations = sysOutDev.titleTranslations;
} else {
    videos = window.sysOut.videos;
    topics = window.sysOut.topics;
    titleTranslations = window.sysOut.titleTranslations;
}

export default {
    state: () => ({
        videos: videos,
        topics: topics,
        titleTranslations: titleTranslations,
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
        videos: state => {
            return state.videos;
        },
        videosList: state => {
            let v = [];
            let t = Object.keys(state.topics);
            t.forEach(topic => {
                if (state.topics[topic].videos && state.topics[topic].videos.length > 0) {
                    v.push(state.topics[topic].videos[0]);
                }
            });
            return v;
        },
        topics: state => {
            return state.topics;
        },
        homeVideos: (state, getters) => {
            let arr = getters.videosList;
            let arrLen = arr.length;
            if (arrLen > 2) {
                return arr.slice(arrLen - 2);
            } else {
                return arr;
            }
        },
        translatedTitle: (state) => (topic) => {
            if (state?.titleTranslations && state?.titleTranslations[topic]) {
                return state?.titleTranslations[topic];
            } else {
                return null;
            }
        }
    }
}
