<template>
  <div class="page">
    <section class="projects">
      <ProjectList :projects="projects" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectList from "../components/projects/ProjectList";

export default {
  name: "Projects",
  components: {
    ProjectList
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
        'projects'
    ]),
  },
  methods: {
  },
  created() {
  },
};
</script>

<style scoped>
.projects {
  padding-bottom: 2%;
}
</style>
