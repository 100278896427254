<template>
  <div class="PartnerHeader">
    <div :class="{'logos':true, 'em-7-height':(showSAWBOLogo), 'em-5-height':(!showSAWBOLogo)}">
      <div :class="{'partner-logo-cont':true,'partner-logo-small-cont':(showSecondaryLogo)}">
        <a v-if="linkPartnerWebsite" :href="website" target="_blank">
          <img :src="logo" class="partner-logo" />
        </a>
        <img v-else :src="logo" class="partner-logo" />
      </div>
      <div v-if="showSecondaryLogo" :class="{'partner-logo-cont':true,'partner-secondary-logo-small-cont':(showSecondaryLogo)}">
        <a v-if="linkSecondaryWebsite" :href="secondaryWebsite" target="_blank">
          <img :src="secondaryLogo" class="partner-logo" />
        </a>
        <img v-else :src="secondaryLogo" class="partner-logo" />
      </div>
      <div :class="{'sawbo-logo-cont':true,'sawbo-logo-small-cont':(showSecondaryLogo)}" v-if="showSAWBOLogo">
        <a href="https://sawbo-animations.org" target="_blank">
          <img
            src="https://sawbo-animations.org/moalfac/img/SAWBO_Logo_500px.jpg"
            class="sawbo-logo"
          />
        </a>
      </div>
    </div>
    <div class="rapid-wordmark">SAWBO {{ siteTitle }}</div>
    <div v-if="showTranslations" class="rapid-wordmark">
      {{ translatedTitle }}
    </div>
    <nav class="rapid-bar-menu">
      <RouterLink 
        :class="isActive('videos')" 
        to="/"
        @click="scrollToTop"
      >Videos</RouterLink>
      <RouterLink 
        :class="isActive('members')" 
        to="/members"
        @click="scrollToTop"
      >Members</RouterLink>
      <RouterLink 
        :class="isActive('news')" 
        to="/news"
        @click="scrollToTop"
      >News</RouterLink>
      <RouterLink 
        :class="isActive('projects')" 
        to="/projects"
        @click="scrollToTop"
      >Projects</RouterLink>
      <RouterLink 
        :class="isActive('about')" 
        to="/about"
        @click="scrollToTop"
      >About</RouterLink>
      <a
        v-if="linkPartnerWebsite"
        :href="website"
        target="_blank"
        class="rapid-bar-menu-link"
        >{{ abbr }} Website</a
      >
      <a
        v-if="showSAWBOLink"
        href="https://sawbo-animations.org"
        target="_blank"
        class="rapid-bar-menu-link"
        >SAWBO Website</a
      >
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PartnerHeader",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["organization", "siteInfo"]),
    name() {
      return this.organization.name;
    },
    abbr() {
      return this.organization.abbreviation;
    },
    website() {
      return this.organization.website;
    },
    logo() {
      return this.organization.logo;
    },
    siteTitle() {
      return this.siteInfo.title;
    },
    translatedTitle() {
      return this.siteInfo.translatedTitle;
    },
    showSAWBOLogo() {
      return this.siteInfo.showSAWBOLogo;
    },
    showSAWBOLink() {
      return this.siteInfo.showSAWBOLink;
    },
    linkPartnerWebsite() {
      if (this.siteInfo?.partnerWebsite && this.siteInfo?.partnerWebsite?.length !== 0) {
        return true;
      } else {
        return false;
      }
    },
    showSecondaryLogo() {
      return this.siteInfo.showSecondaryLogo;
    },
    secondaryLogo() {
      return this.organization.secondaryLogo;
    },
    linkSecondaryWebsite() {
      if (this.siteInfo?.partnerSecondaryWebsite && this.siteInfo?.partnerSecondaryWebsite?.length !== 0) {
        return true;
      } else {
        return false;
      }
    },
    secondaryWebsite() {
      return this.organization.secondaryWebsite;
    },
    showTranslations() {
      return this.siteInfo.showTranslations;
    },
    hideActiveLink() {
      return this.siteInfo.hideActiveLink;
    }
  },
  methods: {
    isActive(route) {
      let classes = "rapid-bar-menu-link";
      if(route === this.$route.name) {
        if (this.hideActiveLink) {
          classes += " rapid-bar-menu-link-hide";
        } else {
          classes += " rapid-bar-menu-link-bold";
        }
      }
      return classes;
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
};
</script>

<style scoped>

.PartnerHeader {
  width: 100%;
  padding: 2% 0;
}

.logos {
  width: 100%;
  padding: 0 5%;
  border-bottom: 2px solid #fafafa;
}

.em-10-height {
  height: 10em;
}

.em-7-height {
  height: 7em;
}

.em-5-height {
  height: 5em;
}

.partner-logo-cont {
  width: 45%;
  height: 100%;
  float: left;
  padding-top: 5%;
}

.partner-logo-small-cont {
  width: 32% !important;
}

.partner-secondary-logo-small-cont {
  width: 32% !important;
  padding-top: 2.5% !important;
  padding-bottom: 2.5% !important;
}

.sawbo-logo-small-cont {
  width: 32% !important;
  padding-top: 3% !important;
}

.partner-logo {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.sawbo-logo-cont {
  width: 45%;
  height: 100%;
  float: right;
}

.sawbo-logo {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  vertical-align: middle;
}

.rapid-wordmark {
  width: 100%;
  padding: 0 5%;
  color: #3b5783;
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
}

.rapid-bar-menu {
  padding: 0.5% 5%;
  background-color: #3b5783;
  color: #ffffff;
  text-align: right;
}

.rapid-bar-menu-link {
  color: #ffffff;
  text-decoration: none;
  padding-left: 15px;
}

.rapid-bar-menu-link:hover {
  text-decoration: underline;
}

.rapid-bar-menu-link-hide {
  display: none !important;
}

.rapid-bar-menu-link-bold {
  font-weight: bold;
}
</style>
