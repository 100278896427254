export default {
    state: () => ({
        componentKey: 0
    }),
    mutations: {
        'INCREMENT_COMPONENT_KEY'(state) {
            state.componentKey++;
        }
    },
    actions: {
        forceRender({ commit }) {
            commit('INCREMENT_COMPONENT_KEY');
        }
    },
    getters: {
        componentKey: state => {
            return state.componentKey;
        },
    }
}
