<template>
    <div class="video-list">
        <MemberItem 
            v-for="m in members" :key="m"
            :member="m"
            class="video-item"
        />
    </div>
</template>

<script>
import MemberItem from './MemberItem'

export default {
  name: 'MemberList',
  components: {
      MemberItem,
  },
  props: {
    members: {
        type: Array,
        required: true,
    }
  },
  data () {
      return {
        
      }
  },
  computed: {
  },
  created() {
  }

}
</script>

<style scoped>
.video-list {
    width: 100%;
}
.video-item {
    width: 100%;
    display: block;
    padding-bottom: 5%;
}

@media only screen and (min-width: 745px) {
.video-item {
    width: 50%;
    display: inline-block;
    vertical-align:top;
}
}
</style>
