import sysOutDev from '../../data/sysOut';

let news;

if (process.env.NODE_ENV === 'development') {
    news = sysOutDev.news;
} else {
    news = window.sysOut.news;
}

export default {
    state: () => ({
        news: news,
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
        news: state => {
            return state.news;
        },
        newsList: state => {
            let newsList = [];
            let n = Object.keys(state.news);
            n.forEach(n => {
                newsList.push(state.news[n]);
            });
            return newsList;
        },
    }
}
