import sysOutDev from '../../data/sysOut';

let projects

if (process.env.NODE_ENV === 'development') {
    projects = sysOutDev.projects;
} else {
    projects = window.sysOut.projects;
}

export default {
    state: () => ({
        projects: projects
    }),
    mutations: {
    },
    actions: {
    },
    getters: {
        projects: state => {
            return state.projects;
        },
    }
}